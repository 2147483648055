<template>
  <div class="empty">
    <!-- placeholder -->
  </div>
</template>

<script>

import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PageGtm',
  mounted() {
    this.$nextTick(() => {
      this.$gtm.push({
        event: 'ecommerce', currencyCode: 'EUR', PageType: 'cms-page',
      });
    });
  },

});
</script>

<style scoped>

</style>
